body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Roboto-Medium";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Roboto-Medium" "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Roboto-Normal" "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
