* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.csNavContainer {
  display: flex;
  flex-direction: 'row';
  justify-content: left !important;
  border-radius: 6px !important;
  font-size: 14px;
  font-family: 'Roboto';
  padding: 10px 10px !important;
  margin: 10px;
  align-items: center;
  background-color: #eaeaea;
  color: #00529c !important;
  font-weight: 600;
}

.csNavContainer {
  background-color: #eaeaea;
  color: #00529c !important;
  font-weight: 600;

  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.csNavContainer img {
  filter: invert(22%) sepia(66%) saturate(2112%) hue-rotate(191deg)
    brightness(60%) contrast(150%);
}

.csSubNavContainer {
  display: none;
}

.selectedNav .csSubNavContainer {
  display: block !important;
}
